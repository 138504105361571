import type { Notification } from '$lib/types/notification';
import { getTimeDifferenceInMs } from '$lib/utils/date';
import { writable } from 'svelte/store';

interface NotificationStoreData {
  notifications: Notification[];
}

const notificationStore = writable<NotificationStoreData>({
  notifications: [],
});

export default {
  update: notificationStore.update,
  subscribe: notificationStore.subscribe,
  set: notificationStore.set,
};

export const updateElapsedTime = () => {
  notificationStore.update((data) => {
    data.notifications = data.notifications.map((notification: Notification) => ({
      ...notification,
      timeElapsedSinceCreation: getTimeDifferenceInMs(new Date(notification.createdAt), new Date()),
    }));
    return data;
  });
};

export const updateLocalNotifications = (notifications: Notification[]) => {
  notificationStore.set({
    notifications: notifications,
  });
  updateElapsedTime();
};

export const flagNotificationAsRead = (readNotification: Notification) => {
  notificationStore.update((data) => {
    data.notifications = data.notifications.map((notification: Notification) => {
      if (notification.id === readNotification.id) {
        return { ...notification, readAt: readNotification.readAt };
      }
      return notification;
    });
    return data;
  });
  updateElapsedTime();
};
