<script lang="ts">
  import Ai8Icon from '$lib/assets/icons/ai8-icon.svg';
  import NotificationsDropdownIcon from '$lib/components/projectBoard/navbar/NotificationsDropdownIcon.svelte';
  import { NotificationType } from '$lib/enums/notification';
  import * as notificationService from '$lib/services/notification';
  import notificationStore, {
    flagNotificationAsRead,
    updateElapsedTime,
    updateLocalNotifications,
  } from '$lib/stores/notificationStore';
  import taigaEventStore from '$lib/stores/taigaEventStore';
  import workspaceStore from '$lib/stores/workspaceStore';
  import type { CreateNotificationEventContent } from '$lib/types/taiga-events';
  import { NOTIFICATION_INFO } from '$lib/utils/constants';
  import { formatElapsedTime } from '$lib/utils/date';
  import { Avatar, Dropdown, DropdownHeader, DropdownItem } from 'flowbite-svelte';
  import { onDestroy, onMount } from 'svelte';

  const NOTIFICATIONS_FETCH_SIZE = 5;

  let isNotificationsDropdownOpen = false;
  let lastNotificationTimestamp: string | null = null;
  let allNotificationsLoaded: boolean = false;
  let dropdownDiv: HTMLDivElement | null;

  const onCreateNotificationEvent = (changeContent: CreateNotificationEventContent) => {
    const updatedNotifications = [
      notificationService.formatNotification(changeContent.notification),
      ...$notificationStore.notifications,
    ];
    updateLocalNotifications(updatedNotifications);
  };

  onMount(async () => {
    try {
      await loadNotifications();
      taigaEventStore.subscribeToCreateNotificationEvents(onCreateNotificationEvent);
      subscribeToWorkspaceEvents();
    } catch (e) {
      console.error(e);
    }
  });

  onDestroy(() => {
    taigaEventStore.unsubscribeFromCreateNotificationEvents(onCreateNotificationEvent);
    unsubscribeToWorkspaceEvents();
  });

  const subscribeToWorkspaceEvents = () => {
    const workspaceId = $workspaceStore.workspaceId;
    taigaEventStore.subscribeToWorkspaceEvents(workspaceId);
    taigaEventStore.subscribeToWorkspaceCreateNotificationEvents(workspaceId, onCreateNotificationEvent);
  };

  const unsubscribeToWorkspaceEvents = () => {
    const workspaceId = $workspaceStore.workspaceId;
    taigaEventStore.unsubscribeFromWorkspaceEvents(workspaceId);
    taigaEventStore.unsubscribeFromWorkspaceCreateNotificationEvents(workspaceId, onCreateNotificationEvent);
  };

  $: if (isNotificationsDropdownOpen) {
    updateElapsedTime();
  }

  const loadNotifications = async () => {
    if (allNotificationsLoaded) {
      return;
    }

    const data = await notificationService.getNotifications(NOTIFICATIONS_FETCH_SIZE, lastNotificationTimestamp);
    const loadedNotifications = data.notifications;
    lastNotificationTimestamp = data.lastNotificationTimestamp;
    const mergedNotifications = $notificationStore.notifications.concat(loadedNotifications);
    updateLocalNotifications(mergedNotifications);

    //If fewer records were returned than the fetch size, all data has been loaded
    if (loadedNotifications.length < NOTIFICATIONS_FETCH_SIZE) {
      allNotificationsLoaded = true;
    }

    if (dropdownDiv) {
      // Scrolls the dropdown to the bottom when additional notifications are loaded
      dropdownDiv.scrollTop = dropdownDiv.scrollHeight;
    }
  };

  const readNotification = async (notificationId: string) => {
    try {
      flagNotificationAsRead(await notificationService.markNotificationAsRead(notificationId));
    } catch (e) {
      console.error(e);
    }
  };

  const readAllNotifications = async () => {
    try {
      updateLocalNotifications(await notificationService.markAllNotificationsAsRead());
    } catch (e) {
      console.error(e);
    }
  };

  $: hasUnreadNotifications = $notificationStore.notifications.some((notification) => !notification.readAt);
</script>

<NotificationsDropdownIcon {isNotificationsDropdownOpen} {hasUnreadNotifications} />
<Dropdown bind:open={isNotificationsDropdownOpen} containerClass="shadow-2xl mr-4 w-[30vw] z-10 dark:bg-gray-700">
  <DropdownHeader>
    <div class="flex justify-between">
      <span class="font-medium text-md text-gray-800 dark:text-white">Notifications</span>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      {#if hasUnreadNotifications}
        <div class="text-md hover:underline text-black dark:text-white cursor-pointer" on:click={readAllNotifications}>
          Mark all as read
        </div>
      {/if}
    </div>
  </DropdownHeader>
  <div bind:this={dropdownDiv} class="h-[38vh] overflow-y-auto overflow-x-hidden">
    {#each $notificationStore.notifications as notification}
      <DropdownItem
        on:click={() => readNotification(notification.id)}
        class="flex items-start text-base {notification.readAt
          ? 'bg-white hover:bg-white dark:bg-gray-400 hover:dark:bg-gray-400 cursor-default'
          : 'bg-gray-100 hover:bg-gray-100 dark:bg-gray-300 hover:dark:bg-gray-300'} rounded-lg my-2 ml-1 gap-2"
      >
        {#if notification.type !== NotificationType.CUSTOM_NOTIFICATION}
          <Avatar size="xs" />
        {:else}
          <img src={Ai8Icon} alt="AI8-Icon" width="20px" />
        {/if}
        <div
          class="font-normal flex flex-col {notification.readAt
            ? 'text-gray-500 dark:text-gray-800'
            : 'text-black font-medium'}"
        >
          {#if notification.type !== NotificationType.CUSTOM_NOTIFICATION}
            <div>
              <span class="font-semibold">{notification[NOTIFICATION_INFO[notification.type].owner]?.fullName}</span>
              <span>{NOTIFICATION_INFO[notification.type].description}</span>
            </div>
            <div class="flex">
              <span class="font-semibold">#{notification.story.ref} {notification.story.title}</span>

              {#if notification.type === NotificationType.STORIES_STATUS_CHANGE}
                <span class="ml-1 {notification.readAt ? 'text-gray-500 dark:text-gray-800' : 'text-black'}"
                  >to {notification.status}</span
                >
              {/if}
            </div>
          {:else}
            <div class="flex flex-col">
              <span class="font-semibold">{notification.title}</span>
              <span>{notification.description}</span>
            </div>
          {/if}
          <span class="text-gray-400 dark:text-black mt-2"
            >{formatElapsedTime(notification.timeElapsedSinceCreation)}</span
          >
        </div>
      </DropdownItem>
    {/each}
  </div>
  <DropdownItem slot="footer">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="flex justify-center text-md {allNotificationsLoaded
        ? 'text-gray-400'
        : 'hover:underline text-black dark:text-white'}"
      on:click={loadNotifications}
    >
      Load more
    </div>
  </DropdownItem>
</Dropdown>
