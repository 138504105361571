<script lang="ts">
  import { goto } from '$app/navigation';
  import Ai8Icon from '$lib/assets/icons/ai8-icon.svg';
  import NotificationsNavbarDropdown from '$lib/components/projectBoard/navbar/NotificationsDropdown.svelte';
  import userStore from '$lib/stores/userStore';
  import workspaceStore from '$lib/stores/workspaceStore';
  import { getLogoutUrl } from '$lib/utils/redirect';
  import { Avatar, Dropdown, DropdownHeader, DropdownItem, NavLi, NavUl, Navbar } from 'flowbite-svelte';
</script>

<Navbar class="dark:bg-gray-800" navDivClass="mx-auto flex flex-wrap justify-between items-center max-w-[100%] px-6">
  <img src={Ai8Icon} alt="AI8-Icon" width="38px" />
  <div class="flex justify-center items-center">
    <div class="mr-6">
      <NavUl ulClass="flex items-center">
        <NavLi>
          {#if $workspaceStore?.workspaceId}
            <NotificationsNavbarDropdown />
          {/if}
        </NavLi>
      </NavUl>
    </div>

    <NavUl ulClass="flex items-center">
      <NavLi>
        <Avatar id="user-drop" class="cursor-pointer" />
        <Dropdown triggeredBy="#user-drop">
          <DropdownHeader>
            <span class="block truncate text-sm font-medium">{$userStore.user?.email}</span>
          </DropdownHeader>
          <DropdownItem on:click={() => goto(getLogoutUrl())}>Sign out</DropdownItem>
        </Dropdown>
      </NavLi>
    </NavUl>
  </div>
</Navbar>
