import type { NotificationListResponse, NotificationResponse } from '$lib/types/response';
import { taigaApi } from '$src/axios/api';
import type { AxiosPromise } from 'axios';

const NOTIFICATIONS_API_BASE_ROUTE = 'my/notifications';
const PM_NOTIFICATIONS_API_BASE_ROUTE = 'pm/' + NOTIFICATIONS_API_BASE_ROUTE;

export const getNotifications = (
  pageSize: number | null,
  lastNotificationTimestamp: string | null
): AxiosPromise<NotificationListResponse> => {
  const params = new URLSearchParams();
  if (pageSize) {
    params.append('page_size', pageSize.toString());
  }

  if (lastNotificationTimestamp) {
    params.append('last_notification_timestamp', lastNotificationTimestamp);
  }

  return taigaApi.get(PM_NOTIFICATIONS_API_BASE_ROUTE, { params });
};

export const markNotificationAsRead = (id: string): AxiosPromise<NotificationResponse> => {
  return taigaApi.post(NOTIFICATIONS_API_BASE_ROUTE + `/${id}/read`);
};

export const markAllNotificationsAsRead = (): AxiosPromise<NotificationResponse[]> => {
  return taigaApi.post(PM_NOTIFICATIONS_API_BASE_ROUTE + `/read`);
};
