<script lang="ts">
  import DotIcon from '$lib/assets/icons/dot-icon.svg';
  import { BellRegular, BellSolid } from 'svelte-awesome-icons';

  export let isNotificationsDropdownOpen: boolean;
  export let hasUnreadNotifications: boolean;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="relative cursor-pointer">
  {#if isNotificationsDropdownOpen}
    <BellSolid size="25" class="mr-2 pt-0.5 border-none outline-none" />
  {:else}
    <BellRegular size="25" class="mr-2 pt-0.5" />
  {/if}
  {#if hasUnreadNotifications}
    <img src={DotIcon} alt="dot" width="8vw" class="absolute top-0 right-0" />
  {/if}
</div>
