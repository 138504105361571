import * as notificationApi from '$lib/api/notification';
import type { Notification, NotificationsData } from '$lib/types/notification';
import type { NotificationResponse } from '$lib/types/response';
import { getTimeDifferenceInMs } from '$lib/utils/date';

export const getNotifications = async (
  pageSize: number | null,
  lastNotificationTimestamp: string | null
): Promise<NotificationsData> => {
  const data = (await notificationApi.getNotifications(pageSize, lastNotificationTimestamp)).data;
  return {
    notifications: data.notifications.map(formatNotification),
    lastNotificationTimestamp: data.lastNotificationTimestamp,
  };
};

export const markNotificationAsRead = async (id: string): Promise<Notification> => {
  const data = (await notificationApi.markNotificationAsRead(id)).data;
  return formatNotification(data);
};

export const markAllNotificationsAsRead = async (): Promise<Notification[]> => {
  const data = (await notificationApi.markAllNotificationsAsRead()).data;
  return data.map(formatNotification);
};

export const formatNotification = (notification: NotificationResponse): Notification => {
  const { content, ...rest } = notification;
  return {
    ...rest,
    createdAt: new Date(notification.createdAt),
    readAt: notification.readAt ? new Date(notification.readAt) : null,
    story: content.story,
    assignedBy: content?.assignedBy ?? null,
    unassignedBy: content?.unassignedBy ?? null,
    changedBy: content?.changedBy ?? null,
    status: content?.status ?? null,
    deletedBy: content?.deletedBy ?? null,
    commentedBy: content?.commentedBy ?? null,
    title: content?.title ?? null,
    description: content?.description ?? null,
    timeElapsedSinceCreation: getTimeDifferenceInMs(new Date(notification.createdAt), new Date()),
  };
};
