<script lang="ts">
  import { BUTTON_STYLE } from '$lib/components/projectBoard/generic/PMButton/PMButton';
  import PMButton from '$lib/components/projectBoard/generic/PMButton/PMButton.svelte';
  import * as feedbackService from '$lib/services/feedback';
  import feedbackStore from '$lib/stores/feedbackStore';
  import type { FeedbackRequest } from '$lib/types/request';
  import { toast } from '@zerodevx/svelte-toast';
  import { Textarea } from 'flowbite-svelte';
  import { createEventDispatcher } from 'svelte';

  let feedback: FeedbackRequest = {
    about: $feedbackStore.pageName,
    text: '',
  };
  const dispatch = createEventDispatcher();

  const send = async (e: Event) => {
    e.stopPropagation();
    if (!feedback.about.trim() || !feedback.text.trim()) {
      toast.push('All fields must have a value.');
      return;
    }

    try {
      await feedbackService.sendFeedback(feedback);
      toast.push('Thank you for reaching out. We will get back to you as soon as possible during our working hours.', {
        classes: ['success'],
      });
    } catch (e) {
      console.error(e);
      toast.push('Error: Failed to send feedback');
    } finally {
      dispatch('handleShowPopUp', false);
    }
  };

  const changeFeedback = (event: Event) => {
    const target = event.target as HTMLInputElement;
    feedback = { ...feedback, text: target.value };
  };
</script>

<div class="fixed border bottom-20 md:right-2 rounded-lg bg-white dark:bg-gray-700 md:w-[22%] md:max-w-[22%] px-4">
  <div class="w-full h-10 flex items-center relative font-semibold mt-1">Get Help or Provide Feedback</div>
  <hr class="w-full h-px mt-1 mb-2 border-gray-200 dark:border-gray-500" />
  <div class="mb-3">Leave your message here, and we'll get back to you promptly.</div>
  <Textarea
    class="bg-gray-100 mb-2 dark:bg-[#2C3746]"
    id="feedback"
    placeholder="Your message"
    rows="4"
    name="feedback"
    value={feedback.text}
    on:input={changeFeedback}
  />
  <PMButton
    style={!feedback.text ? BUTTON_STYLE.SECONDARY : BUTTON_STYLE.PRIMARY}
    classes="w-full mb-3"
    text={'Send'}
    disabled={!feedback.text}
    on:click={send}
  />
</div>
