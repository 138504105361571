<script lang="ts">
  import { goto } from '$app/navigation';
  import { authenticateTaigaUser } from '$lib/auth/auth';
  import LeaveFeedbackPopUp from '$lib/components/projectBoard/layout/LeaveFeedbackPopUp.svelte';
  import Navbar from '$lib/components/projectBoard/navbar/Navbar.svelte';
  import projectBoardStore from '$lib/stores/projectBoardStore';
  import taigaEventStore from '$lib/stores/taigaEventStore';
  import userStore from '$lib/stores/userStore';
  import { getLogoutUrl } from '$lib/utils/redirect';
  import { setTheme } from '$lib/utils/theme';
  import '$src/app.css';
  import { SvelteToast } from '@zerodevx/svelte-toast';
  import { Button } from 'flowbite-svelte';
  import { onMount } from 'svelte';
  import { MessageRegular } from 'svelte-awesome-icons';

  let showFeedbackPopUp = false;

  onMount(async () => {
    try {
      await authenticateTaigaUser();
      projectBoardStore.set({ isProjectBoard: true });
      setTheme();
      taigaEventStore.connect();
    } catch {
      goto(getLogoutUrl());
    }
  });

  const handleShowPopUp = (e: CustomEvent) => {
    showFeedbackPopUp = e.detail;
  };
</script>

<div class="min-h-screen flex-grow w-full bg-ai8-white dark:text-white dark:bg-ai8-chat-gray">
  {#if $userStore?.user}
    <Navbar />
    <slot />
  {/if}
  <Button
    color="light"
    class={`fixed bottom-3 right-3 rounded-full p-4 dark:bg-white dark:hover:bg-gray-100 focus:ring-0 ${
      showFeedbackPopUp ? 'dark:bg-gray-700 dark:hover:bg-gray-800' : 'bg-gray-800 hover:bg-gray-900'
    }`}
    on:click={() => {
      showFeedbackPopUp = !showFeedbackPopUp;
    }}
  >
    <div class={showFeedbackPopUp ? 'text-black dark:text-white' : 'text-white dark:text-black'}>
      <MessageRegular />
    </div>
  </Button>
  {#if showFeedbackPopUp}
    <LeaveFeedbackPopUp on:handleShowPopUp={handleShowPopUp} />
  {/if}
</div>
<SvelteToast />
